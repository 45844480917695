<template>
  <b-card-code title="المواد المخزنية" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="ابحث عن.."
            />
            <b-input-group-append>
              <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة مادة جديدة</span>
              </b-button>
              <export-excel style="color:white;padding-top:10px"
                  class   = "btn btn-default"
                  :data   = "items"
                  :fields = "exportFields"
                  worksheet = "My Worksheet"
                  name    = "filename.xls">
                  استخراج ملف اكسل
              </export-excel>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col md="3">
          <b-form-group>
            <v-select
              v-model="category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.categories"
              placeholder="اختر تصنيف المواد"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="search.types.length > 0" md="3">
          <b-form-group>
            <v-select
              v-model="type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.types"
              placeholder="اختر نوع المواد"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="search.subtypes.length > 0" md="3">
          <b-form-group>
            <v-select
              v-model="subtype_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.subtypes"
              placeholder="اختر النوع الثانوي"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="search.carency.length > 0" md="3">
          <b-form-group>
            <v-select
              v-model="carency_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.carency"
              placeholder="اختر العملة"
            />
          </b-form-group>
        </b-col>
        <b-col md="3"
          >
            <b-form-group
            >
              <cleave
                id="date"
                v-model="fromdate"
                class="form-control"
                :raw="false"
                :options="date"
                placeholder="من YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <b-col md="3"
          >
            <b-form-group
            >
              <cleave
                id="date"
                v-model="todate"
                class="form-control"
                :raw="false"
                :options="date"
                placeholder="الى YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
      </b-form-row>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              v-b-modal.model-e-w
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                  variant="outline-primary"
                  @click="fEM(action.item)"
                  v-b-modal.model-f-w
                >
                <feather-icon icon="LogOutIcon" class="mr-50" />
                </b-button>
              </b-col>
              <b-col md="3" v-if="action.item.image!=null">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                @click="onClicked(action.item.image)"
              >
                <feather-icon icon="FileTextIcon" class="mr-50" />
              </b-button>
            </b-col>
          <b-col md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                :to="{ name: 'employees-storage-id', params: { id: action.item.id } }"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </b-col>
          <b-col md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                :to="{ name: 'addition', params: { id: action.item.id } }"
              >
                <feather-icon icon="ClipboardIcon" class="mr-50" />
              </b-button>
            </b-col>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ التعديلات"
      centered
      size="lg"
      title="تعديل مادة مخزنية"
      @ok="uM"
      @hidden="rEF"
    >
    <b-form novalidate class="needs-validation" >
      <b-form-row>
        <b-col md="6" v-show="types.length > 0">
          <b-form-group>
            <label for="basic-password">نوع المادة</label>
            <v-select
              v-model="editform.type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="types"
              placeholder="اختر نوع المادة"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-show="subtypes.length > 0">
          <b-form-group>
            <label for="basic-password">النوع الثانوي</label>
            <v-select
              v-model="editform.subtype_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="subtypes"
              placeholder="اختر النوع الثانوي"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-show="carency.length > 0">
          <b-form-group>
            <label for="basic-password">العملة</label>
            <v-select
              v-model="editform.carency_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="carency"
              placeholder="اختر النوع الثانوي"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-show="categories.length > 0">
          <b-form-group>
            <label for="basic-password">تصنيف المادة</label>
            <v-select
              v-model="editform.category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="categories"
              placeholder="اختر تصنيف المادة"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <label for="input-valid1">العنوان :</label>
          <b-form-input
            id="input-valid1"
            v-model="editform.title"
            :state="editform.title.length > 0"
            placeholder="العنوان "
          />
        </b-col>
        <b-col md="6">
          <b-form-group
        label="العدد"
        label-for="cardNumber"
      >
        <cleave
          id="cardNumber"
          v-model="editform.count"
          class="form-control"
            :state="editform.count.length > 0"
            :raw="false"
          :options="format.price"
          placeholder="العدد"
        />
      </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
        label="سعر المادة الواحدة"
        label-for="number"
      >
        <cleave
          id="number"
          v-model="editform.price"
          class="form-control"
          :raw="false"
          :options="format.price"
          placeholder="سعر المادة الواحدة"
        />
      </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
        label="رقم الوصل"
        label-for="cardNumber"
      >
        <cleave
          id="cardNumber"
          v-model="editform.invoise"
          class="form-control"
            :state="editform.invoise.length > 0"
            :raw="false"
          :options="format.price"
          placeholder="رقم الوصل"
        />
      </b-form-group>
        </b-col>
        <b-col md="6"
    >
      <b-form-group
        label="تاريخ الوصل"
        label-for="date"
      >
        <cleave
          id="date"
          v-model="editform.date"
          class="form-control"
          :raw="false"
          :options="date"
          placeholder="YYYY-MM-DD"
        />
      </b-form-group>
    </b-col>
        <b-col md="12">
          <label for="input-invalid1">الملاحظات:</label>
          <b-form-textarea
            id="input-invalid1"
            placeholder="الملاحظات"
            v-model="editform.note"
              rows="3"
          />
        </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر صورة الوصل:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
              v-model="editform.file"
              @change="selectFileForEditForm"
            />
          </b-col>
      </b-form-row>
    </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة مادة جديدة"
      @ok="aNM"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation" >
        <b-form-row>
          <b-col md="6" v-show="types.length > 0">
            <b-form-group>
              <label for="basic-password">نوع المادة</label>
              <v-select
                v-model="form.type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="types"
                placeholder="اختر نوع المادة"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-show="subtypes.length > 0">
            <b-form-group>
              <label for="basic-password">النوع الثانوي</label>
              <v-select
                v-model="form.subtype_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="subtypes"
                placeholder="اختر النوع الثانوي"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-show="categories.length > 0">
            <b-form-group>
              <label for="basic-password">تصنيف المادة</label>
              <v-select
                v-model="form.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="categories"
                placeholder="اختر تصنيف المادة"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-show="carency.length > 0">
            <b-form-group>
              <label for="basic-password">تصنيف المادة</label>
              <v-select
                v-model="form.carency_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="carency"
                placeholder="اختر العملة"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="input-valid1">العنوان :</label>
            <b-form-input
              id="input-valid1"
              v-model="form.title"
              :state="form.title.length > 0"
              placeholder="العنوان "
            />
          </b-col>
          <b-col md="6">
            <b-form-group
          label="العدد"
          label-for="cardNumber"
        >
          <cleave
            id="cardNumber"
            v-model="form.count"
            class="form-control"
              :state="form.count.length > 0"
              :raw="false"
            :options="format.price"
            placeholder="العدد"
          />
        </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
          label="سعر المادة الواحدة"
          label-for="number"
        >
          <cleave
            id="number"
            v-model="form.price"
            class="form-control"
            :raw="false"
            :options="format.price"
            placeholder="سعر المادة الواحدة"
          />
        </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
        label="رقم الوصل"
        label-for="cardNumber"
      >
        <cleave
          id="cardNumber"
          v-model="form.invoise"
          class="form-control"
            :state="form.invoise.length > 0"
            :raw="false"
          :options="format.price"
          placeholder="رقم الوصل"
        />
      </b-form-group>
          </b-col>
          <b-col md="6"
      >
        <b-form-group
          label="تاريخ الوصل"
          label-for="date"
        >
          <cleave
            id="date"
            v-model="form.date"
            class="form-control"
            :raw="false"
            :options="date"
            placeholder="YYYY-MM-DD"
          />
        </b-form-group>
      </b-col>
          <b-col md="12">
            <label for="input-invalid1">الملاحظات:</label>
            <b-form-textarea
              id="input-invalid1"
              placeholder="الملاحظات"
              v-model="form.note"
                rows="3"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر صورة الوصل:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
            v-model="form.file"
            @change="selectFileForForm"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
          id="model-f-w"
          ok-title="حفظ البيانات"
          centered
          size="lg"
          title="تحويل مواد"
          @ok="fM"
          @hidden="rFM"
        >
          <b-form novalidate class="needs-validation">
            <b-form-row>
              <b-col md="12">
                <b-form-group>
                  <label for="basic-password">موجه الى</label>
                  <v-select
                    v-model="forwardform.user_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="full_name"
                    :options="users"
                    placeholder="اختر المنتسبين"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
            <b-form-group
          label="العدد"
          label-for="cardNumber"
        >
          <cleave
            id="cardNumber"
            v-model="forwardform.count"
            class="form-control"
              :state="forwardform.count.length > 0"
              :raw="false"
            :options="format.number"
            placeholder="العدد"
          />
        </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
          label="رقم مستند الاخراج "
          label-for="cardNumber"
        >
          <cleave
            id="cardNumber"
            v-model="forwardform.invoise"
            class="form-control"
              :state="forwardform.count.length > 0"
              :raw="false"
            :options="format.number"
            placeholder="رقم مستند"
          />
        </b-form-group>
          </b-col>
          
          <b-col md="4"
      >
        <b-form-group
          label="تاريخ التحويل"
          label-for="date"
        >
          <cleave
            id="date"
            v-model="forwardform.date"
            class="form-control"
            :raw="false"
            :options="date"
            placeholder="YYYY-MM-DD"
          />
        </b-form-group>
      </b-col>
          <b-col md="12">
            <label for="input-invalid1">الملاحظات:</label>
            <b-form-textarea
              id="input-invalid1"
              placeholder="الملاحظات"
              v-model="forwardform.note"
                rows="3"
            />
          </b-col>
            </b-form-row>
          </b-form>
        </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import { filesUrl } from "@/main.js";
import store from "@/store";
import 'cleave.js/dist/addons/cleave-phone.us'
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,BProgress,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,BFormTextarea,
  BButton,
  BCardBody,BFormFile
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,
    BAvatar,
    BBadge,
    BFormRow,BProgress,BFormTextarea,
    BDropdown,
    Cleave,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,BFormFile
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 5,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      users:[],
      role: null,
      canDelete: null,
      canEdit: null,
      currentPage: 0,
      category_id: "",
      subtype_id: "",
      type_id: "",
      fromdate:"",
      todate:"",
      carency_id:"",
      carency:[],
      title: "",
      filter: null,
      date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
          },
      format:{
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        number: {
          creditCard: true,
        },
      },
      forwardform:{
        user_id:"",
        instorage_id:"",
        count:"",
        note:"",
        invoise:"",
        date:""
      },
      filterOn: [],
      departments: [],
        categories: [],
        types: [],
        subtypes: [],
      search:{
        categories: [],
        subtypes: [],
        types: [],
        carency:[]
      },
      fields: [
        {
          key: "title",
          label: "العنوان",
        },
        {
          key: "count",
          label: "عدد المواد",
        },
        {
          key: "price",
          label: "سعر المادة",
        },
        {
          key: "invoise",
          label: "رقم الوصل",
        },
        {
          key: "date",
          label: "تاريخ الوصل",
        },
        {
          key: "note",
          label: "ملاحظة",
        },
        {
          key: "carency.title",
          label: "عملة الشراء",
        },
        { key: "type.ar_title", label:"نوع المادة" },
        {
          key: "subtype.ar_title",
          label: "النوع الثانوي",
        },
        { key: "action", label: "الاجراءات" },
      ],
      exportFields:{
          'العنوان': 'title',
            'عدد المواد': 'count',
            'سعر المادة': 'price',
            'رقم الوصل': 'invoise',
            'تاريخ الوصل': 'date',
            'ملاحظة': 'note',
            'عملة الشراء': 'carency.title',
            'نوع المادة': 'type.ar_title',
            'النوع الثانوي': 'subtype.ar_title',
      },
      exportData:[
        {
          title: "title",
          count: "count",
          price: "price",
          invoise: "invoise",
          date: "date",
          note: "note",
          carency: 'carency.title',
          type: 'type.ar_title',
          subtype: 'subtype.ar_title',
        },
      ],
      items: [],
      form: {
        title: "",
        type_id: "",
        subtype_id: "",
        category_id: "",
        count: "",
        price: "",
        writen:"",
        note: "",
        invoise: "",
        date: "",
        file:""
      },
      editform: {
        title: "",
        type_id: "",
        subtype_id: "",
        category_id: "",
        count: "",
        price: "",
        writen:"",
        note: "",
        invoise: "",
        date: "",
        file:"",
        id: null
      },
      selected: [],
      selected2: [],
      options: {
        time: {
          time: true,
          timePattern: ["h", "m"],
        },
      },
    };
  },
  watch: {
    title: function (val) {
      this.gS();
    },
    "category_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },
    "type_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },
    "subtype_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },
    "carency_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },
    "fromdate": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },
    "todate": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gS(), this.gSM(), this.gSM1(), this.gSM2(), this.gSM3(), this.gU();
  },
  methods: {
    async gSM() {
      await this.axios
        .get(
          `types?str=true`
        )
        .then((res) => {
          this.types = [];
          this.search.types = [];
          this.types = res.data.items;
          this.search.types = res.data.items;
        })
    },
    async gSM1() {
      await this.axios
        .get(
          `subtypes`
        )
        .then((res) => {
          this.subtypes = [];
          this.search.subtypes = [];
          this.subtypes = res.data.items;
          this.search.subtypes = res.data.items;
        })
    },
    async gSM2() {
      await this.axios
        .get(
          `categories?take=100&skip=0&str=true`
        )
        .then((res) => {
          this.categories = [];
          this.search.categories = [];
          this.categories = res.data.items;
          this.search.categories = res.data.items;
        })
    },async gSM3() {
      await this.axios
        .get(
          `carency`
        )
        .then((res) => {
          this.carency = [];
          this.search.carency = [];
          this.carency = res.data.items;
          this.search.carency = res.data.items;
        })
    },
    async gU() {
      await this.axios
        .get(`users-name`)
        .then((res) => {
          this.users = [];
          this.users = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async uM() {
      if (isNaN(this.editform.type_id)) {
        this.editform.type_id = this.editform.type_id["id"];
      }
      if (isNaN(this.editform.subtype_id)) {
        this.editform.subtype_id = this.editform.subtype_id["id"];
      }
      if (isNaN(this.editform.category_id)) {
        this.editform.category_id = this.editform.category_id["id"];
      }
      if (isNaN(this.editform.carency_id)) {
        this.editform.carency_id = this.editform.carency_id["id"];
      }
      let data = new FormData();
      data.append("title", this.editform.title);
      data.append("type_id", this.editform.type_id);
      data.append("subtype_id", this.editform.subtype_id);
      data.append("category_id", this.editform.category_id);
      data.append("count", this.editform.count);
      data.append("price", this.editform.price);
      data.append("invoise", this.editform.invoise);
      data.append("date", this.editform.date);
      data.append("note", this.editform.note);
      data.append("carency_id", this.editform.carency_id);
      data.append("file", this.editform.file);
      await this.axios
        .post(`instorage/${this.editform.id}`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
            this.gS();
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
        this.rEF()
    },
    async aNM() {
      if (isNaN(this.form.type_id)) {
        this.form.type_id = this.form.type_id["id"];
      }
      if (isNaN(this.form.subtype_id)) {
        this.form.subtype_id = this.form.subtype_id["id"];
      }
      if (isNaN(this.form.category_id)) {
        this.form.category_id = this.form.category_id["id"];
      }
      if (isNaN(this.form.carency_id)) {
        this.form.carency_id = this.form.carency_id["id"];
      }
      let data = new FormData();
      data.append("title", this.form.title);
      data.append("type_id", this.form.type_id);
      data.append("subtype_id", this.form.subtype_id);
      data.append("category_id", this.form.category_id);
      data.append("count", this.form.count);
      data.append("price", this.form.price);
      data.append("invoise", this.form.invoise);
      data.append("date", this.form.date);
      data.append("note", this.form.note);
      data.append("carency_id", this.form.carency_id);
      data.append("file", this.form.file);
      await this.axios
        .post(`instorage`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
            this.gS();
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async fM() {
      if (isNaN(this.forwardform.user_id)) {
        this.forwardform.user_id = this.forwardform.user_id["id"];
      }
      await this.axios
        .post(`outstorage`, this.forwardform,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
            this.gS();
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
        console.log(this.forwardform.instorage_id)
      this.rFM();
    },
    fEM(item){
      this.forwardform.instorage_id = item.id;
    },
    rFM(){
      this.forwardform.instorage_id = ""
      this.forwardform.user_id=""
      this.forwardform.count=""
      this.forwardform.note=""
    },
    rF() {
      this.form.title = ""
      this.form.type_id = ""
      this.form.subtype_id = ""
      this.form.category_id = ""
      this.form.count = ""
      this.form.price = ""
      this.form.writen = ""
      this.form.note = ""
      this.form.invoise = ""
      this.form.carency_id = ""
      this.form.date = ""
      this.form.file = ""
    },
    rEF() {
      this.editform.title = ""
      this.editform.type_id = ""
      this.editform.subtype_id = ""
      this.editform.category_id = ""
      this.editform.carency_id = ""
      this.editform.count = ""
      this.editform.price = ""
      this.editform.writen = ""
      this.editform.note = ""
      this.editform.invoise = ""
      this.editform.date = ""
      this.editform.file = ""
      this.editform.id = "";
    },
    async dM() {
      // console.log(this.$router.currentRoute.name)
      await this.axios
        .delete(`tables/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      this.editform.title = item.title;
      this.editform.type_id = item.type_id;
      this.editform.subtype_id = item.subtype_id;
      this.editform.category_id = item.category_id;
      this.editform.carency_id = item.carency_id;
      this.editform.count = item.count;
      this.editform.price = item.price;
      this.editform.writen = item.writen;
      this.editform.note = item.note;
      this.editform.invoise = item.invoise;
      this.editform.date = item.date;
      this.editform.file = item.image;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.title = item.title;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      if (isNaN(this.type_id)) {
        this.type_id = this.type_id["id"];
      }
      if (isNaN(this.subtype_id)) {
        this.subtype_id = this.subtype_id["id"];
      }
      if (isNaN(this.category_id)) {
        this.category_id = this.category_id["id"];
      }
      if (isNaN(this.carency_id)) {
        this.carency_id = this.carency_id["id"];
      }
      await this.axios
        .get(
          `instorage?take=${this.perPage}&skip=${this.currentPage - 1}&title=${
            this.title
          }&type_id=${this.type_id}&subtype_id=${this.subtype_id}
          &category_id=${this.category_id}&carency_id=${this.carency_id}&fromdate=${this.fromdate}&todate=${this.todate}` 
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch() {
      this.title = "",
      this.category_id = "",
      this.subtype_id= "",
      this.type_id= "",
      this.carency_id= "",
      this.todate= "",
      this.fromdate= "",
      this.gS();
    },
    selectFileForForm(e) {
      this.form.file = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.form.file = e.target.files[0];
      console.log(this.form.files);
    },
    selectFileForEditForm(e) {
      this.editform.file = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.editform.file = e.target.files[0];
      console.log(this.editform.file);
    },
    onClicked(path) {
      console.log(path)
      window.open(`${filesUrl}/${path}`, "_blank");
    },
  },
};
</script>
